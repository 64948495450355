<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :construction.sync="construction"
          :isOld.sync="isOld"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      construction: {
        sopConstructionId: '',  // 공사 일련번호
        constructionName: '',  // 공사명
        constructionDetails: '',  // 공사내역
        constructionStartDate: '',  // 공사 시작일
        constructionEndDate: '',  // 공사 종료일
        period: [],
        constructionFinishFlag: 'N',  // 공사 종료 여부
        safetyHealthManageCost: 0,  // 안전보건관리비(총 액)
        costCenter: '',  // 코스트센터
        onSiteManager: '',  // 현장 총괄
        safetyManager: '',  // 안전책임자
        vendorCd: '',  // 주 공사업체
        sopMocId: '',  // MOC 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendors: [], // 참영업체
        deleteVendors: [], // 참영업체 [삭제]
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    isOld() {
      return Boolean(this.popupParam.sopConstructionId)
    },
    tabItems() {
      let _items = [
        { name: 'base', icon: 'info', label: '공사정보', component: () => import(`${'./constructionInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({ name: 'status', icon: 'info', label: '공사관련 작업 현황', component: () => import(`${'./constructionRelactionTask.vue'}`) })
      }
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.scm.construction.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopConstructionId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.construction, _result.data)
          this.$set(this.construction, 'period', [this.construction.constructionStartDate, this.construction.constructionEndDate])
          if (this.construction.vendors && this.construction.vendors.length > 0) {
            this.$_.forEach(this.construction.vendors, item => {
              item.period = [item.participationStartDate, item.participationEndDate]
            })
          }

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 신규등록인 경우
        // nothing...
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>